import React, { useState } from "react"
import styled from "styled-components"

const SwitchButton = ({ onClick = () => {} }) => {
  const [buttonState, setButtonState] = useState("unchecked")

  return (
    <ButtonOuter
      onClick={() => {
        setButtonState(buttonState == "checked" ? "unchecked" : "checked")
        onClick()
      }}
    >
      <ButtonInner className={buttonState}></ButtonInner>
    </ButtonOuter>
  )
}

export default SwitchButton

const ButtonOuter = styled.div`
  display: flex;
  width: 50px;
  height: 30px;
  margin: 10px 0;
  border-radius: 15px;
  box-shadow: 0 0 3px 1px ${({ theme }) => theme.white} inset;
  transition: all 0.1s linear;

  &:hover {
    box-shadow: 0 0 3px 2px ${({ theme }) => theme.white} inset;
  }
`
const ButtonInner = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: relative;
  left: 0;
  background-color: ${({ theme }) => theme.peach};
  transition: all 0.2s ease-in-out;

  &.checked {
    background-color: ${({ theme }) => theme.green};
    left: 22px;
  }
`
