import React, { useState } from "react"
import styled from "styled-components"
import Checkmark from "../../images/svg-icons/checkmark"

const Checkbox = ({ onClick = () => {} }) => {
  const [buttonState, setButtonState] = useState("unchecked")

  return (
    <ButtonOuter
      className={buttonState}
      onClick={() => {
        setButtonState(buttonState == "checked" ? "unchecked" : "checked")
        onClick()
      }}
    >
      <Checkmark></Checkmark>
    </ButtonOuter>
  )
}

export default Checkbox

const ButtonOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px ${({ theme }) => theme.white} inset;
  transition: all 0.1s linear;

  svg {
    opacity: 0;
    width: 15px;
    transition: all 0.1s linear;
    path {
      stroke: ${({ theme }) => theme.white};
      transition: all 0.1s linear;
    }
  }

  &:hover {
    box-shadow: 0 0 3px 2px ${({ theme }) => theme.white} inset;

    svg {
      opacity: 0.3;
      path {
        stroke-width: 3px;
      }
    }
  }

  &.checked {
    background-color: ${({ theme }) => theme.green};
    box-shadow: none;

    &:hover {
      box-shadow: 0 0 3px 2px ${({ theme }) => theme.white} inset;
    }

    svg {
      opacity: 1;
      path {
        stroke-width: 3px;
      }
    }
  }
`
