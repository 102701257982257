import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"

const colors = {
  white: "#ffffff",
  grey: "#231f20",
  green: "#a5c11c",
  peach: "#ff614a",
}

const theme = {
  ...colors,
}

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${theme.grey};
        color: ${theme.white};
    }

    h1 {
        color: ${theme.white};
    }
`

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default ThemeConfig
